import { render, staticRenderFns } from "./PointLeaderBoard.vue?vue&type=template&id=710d4a99&scoped=true&"
import script from "./PointLeaderBoard.vue?vue&type=script&lang=js&"
export * from "./PointLeaderBoard.vue?vue&type=script&lang=js&"
import style0 from "./PointLeaderBoard.vue?vue&type=style&index=0&id=710d4a99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710d4a99",
  null
  
)

export default component.exports