<template>
    <div class="">
        <!-- leaderboard type -->
        <div
            class="d-flex align-items-center justify-content-center gap-5 leaderboard-header"
        >
            <div
                :class="{ 'border-bottom-color': tab === 1 }"
                class="d-flex flex-column align-items-center justify-content-center leaderboard"
                @click="() => (tab = 1)"
            >
                <img
                    src="@/assets/img/Royalty.png"
                    alt=""
                    style="width: 3rem;"
                />
                <p style="color: #855e0a" class="m-0">Loyalty</p>
                <p style="color: #855e0a">
                    {{ user ? user.product_point : '' }}
                </p>
            </div>
            <div
                :class="{ 'border-bottom-color': tab === 2 }"
                class="d-flex flex-column align-items-center justify-content-center leaderboard"
                @click="() => (tab = 2)"
            >
                <img
                    src="@/assets/img/Mining.png"
                    alt=""
                    style="width: 3rem;"
                />
                <p style="color: #855e0a" class="m-0">Mining</p>
                <p style="color: #855e0a">{{ user ? user.point : '' }}</p>
            </div>
            <div
                :class="{ 'border-bottom-color': tab === 3 }"
                class="d-flex flex-column align-items-center justify-content-center leaderboard"
                @click="() => (tab = 3)"
            >
                <img
                    src="@/assets/img/Referal1.png"
                    alt=""
                    style="width: 3rem;"
                />
                <p style="color: #855e0a" class="m-0">Referal</p>
                <p style="color: #855e0a">
                    {{ user ? user.referral_point : '' }}
                </p>
            </div>
        </div>
        <div class="mt-3">
            <div v-show="tab === 1">
                <div
                    :class="[
                        myLoyalityData ? 'list-container' : 'container-height',
                    ]"
                >
                    <div
                        v-for="(loyalityLeader, index) in loyalityLeaders"
                        :key="index"
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div
                            class="d-flex align-items-center gap-2"
                            style="color: #855e0a"
                        >
                            <p class="m-0 p-1">
                                {{
                                    loyalityLeader.myIndex
                                        ? loyalityLeader.myIndex
                                        : index + 1
                                }}
                            </p>
                            <p
                                class="m-0 p-1"
                                v-if="loyalityLeader.name"
                                style="max-width: 150px;"
                            >
                                {{ loyalityLeader.name }}
                            </p>
                        </div>
                        <div
                            class="d-flex align-items-start justify-content-between gap-1"
                        >
                            <img
                                src="@/assets/img/Royalty.png"
                                alt=""
                                style="width: 1.5rem;"
                            />
                            <p class="m-0 p-1" style="color: #855e0a">
                                {{ loyalityLeader.product_point }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between checkuser"
                    v-if="myLoyalityData"
                >
                    <div
                        class="d-flex align-items-center gap-2"
                        style="color: #855e0a"
                    >
                        <p class="m-0 p-1">
                            {{ myLoyalityData.index }}
                        </p>
                        <p
                            class="m-0 p-1"
                            v-if="myLoyalityData.name"
                            style="max-width: 150px;"
                        >
                            {{ myLoyalityData.name }}
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-start justify-content-between"
                    >
                        <img
                            src="@/assets/img/Royalty.png"
                            alt=""
                            style="width: 1.5rem;"
                        />
                        <p class="m-0 p-1" style="color: #855e0a">
                            {{ myLoyalityData.product_point }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-show="tab === 2">
                <div
                    :class="[
                        myLoyalityData ? 'list-container' : 'container-height',
                    ]"
                >
                    <div
                        v-for="(miningLeader, index) in miningLeaders"
                        :key="index"
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div
                            class="d-flex align-items-center gap-2"
                            style="color: #855e0a"
                        >
                            <p class="m-0 p-1">
                                {{
                                    miningLeader.myIndex
                                        ? miningLeader.myIndex
                                        : index + 1
                                }}
                            </p>
                            <p
                                class="m-0 p-1"
                                v-if="miningLeader.name"
                                style="max-width: 150px;"
                            >
                                {{ miningLeader.name }}
                            </p>
                        </div>
                        <div
                            class="d-flex align-items-start justify-content-between gap-1"
                        >
                            <img
                                src="@/assets/img/Mining.png"
                                alt=""
                                style="width: 1.5rem;"
                            />
                            <p class="m-0 p-1" style="color: #855e0a">
                                {{ miningLeader.point }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between checkuser"
                    v-if="myMiningData"
                >
                    <div
                        class="d-flex align-items-center gap-2"
                        style="color: #855e0a"
                    >
                        <p class="m-0 p-1">
                            {{ myMiningData.index }}
                        </p>
                        <p
                            class="m-0 p-1"
                            v-if="myMiningData.name"
                            style="max-width: 150px;"
                        >
                            {{ myMiningData.name }}
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-start justify-content-between"
                    >
                        <img
                            src="@/assets/img/Royalty.png"
                            alt=""
                            style="width: 1.5rem;"
                        />
                        <p class="m-0 p-1" style="color: #855e0a">
                            {{ myMiningData.point }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-show="tab === 3">
                <div
                    :class="[
                        myLoyalityData ? 'list-container' : 'container-height',
                    ]"
                >
                    <div
                        v-for="(referalLeader, index) in referalLeaders"
                        :key="index"
                        class="d-flex align-items-center justify-content-between"
                    >
                        <template v-if="referalLeader.name">
                            <div
                                class="d-flex align-items-center gap-2"
                                style="color: #855e0a"
                            >
                                <p class="m-0 p-1">
                                    {{
                                        referalLeader.myIndex
                                            ? referalLeader.myIndex
                                            : index + 1
                                    }}
                                </p>
                                <p
                                    class="m-0 p-1"
                                    v-if="referalLeader.name"
                                    style="max-width: 150px;"
                                >
                                    {{ referalLeader.name }}
                                </p>
                            </div>
                            <div
                                class="d-flex align-items-start justify-content-between gap-1"
                            >
                                <img
                                    src="@/assets/img/Referal1.png"
                                    alt=""
                                    style="width: 1.5rem;"
                                />
                                <p class="m-0 p-1" style="color: #855e0a">
                                    {{ referalLeader.referral_point }}
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="d-flex flex-column align-items-center justify-content-center ps-2"
                                style="line-height: 0.5; color: #855e0a"
                                v-if="showOnHome"
                            >
                                <p
                                    class="m-0 p-0"
                                    v-for="(i, index) in 6"
                                    :key="index"
                                >
                                    .
                                </p>
                            </div>
                            <div v-else></div>
                        </template>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between checkuser"
                    v-if="myReferalData"
                >
                    <div
                        class="d-flex align-items-center gap-2"
                        style="color: #855e0a"
                    >
                        <p class="m-0 p-1">
                            {{ myReferalData.index }}
                        </p>
                        <p
                            class="m-0 p-1"
                            v-if="myReferalData.name"
                            style="max-width: 150px;"
                        >
                            {{ myReferalData.name }}
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-start justify-content-between"
                    >
                        <img
                            src="@/assets/img/Royalty.png"
                            alt=""
                            style="width: 1.5rem;"
                        />
                        <p class="m-0 p-1" style="color: #855e0a">
                            {{ myReferalData.referral_point }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        tab: 1,
    }),
    props: {
        loyalityData: {
            type: Array,
            default: () => [],
        },
        miningData: {
            type: Array,
            default: () => [],
        },
        referalData: {
            type: Array,
            default: () => [],
        },
        showOnHome: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
        }),
        myLoyalityData() {
            let loyality = null;
            {
                if (this.user) {
                    loyality = this.loyalityData.find(
                        ld => ld.id === this.user.id,
                    );
                    let index = this.loyalityData.findIndex(
                        leader => leader.id == this.user.id,
                    );
                    loyality = { ...loyality, index: index };
                }
            }
            return loyality;
        },
        myMiningData() {
            let mining = null;
            {
                if (this.user) {
                    mining = this.miningData.find(ld => ld.id === this.user.id);
                    let index = this.miningData.findIndex(
                        leader => leader.id == this.user.id,
                    );
                    mining = { ...mining, index: index };
                }
            }
            return mining;
        },
        myReferalData() {
            let referal = null;
            {
                if (this.user) {
                    referal = this.referalData.find(
                        ld => ld.id === this.user.id,
                    );
                    let index = this.referalData.findIndex(
                        leader => leader.id == this.user.id,
                    );
                    referal = { ...referal, index: index };
                }
            }
            return referal;
        },
        loyalityLeaders() {
            return this.loyalityData.length > 80
                ? this.loyalityData.slice(0, 80)
                : this.loyalityData;
        },
        miningLeaders() {
            return this.miningData.length > 80
                ? this.miningData.slice(0, 80)
                : this.miningData;
        },
        referalLeaders() {
            return this.referalData.length > 80
                ? this.referalData.slice(0, 80)
                : this.referalData;
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    .leaderboard-header {
        border-bottom: solid 1px #efd59c;
    }
    .border-bottom-color {
        margin-bottom: -2px;
        border-bottom: 2px solid #855e0a;
        position: relative;
        &::after {
            border-right: solid 7px transparent;
            border-left: solid 7px transparent;
            border-top: solid 7px #855e0a;
            transform: translateX(-50%);
            position: absolute;
            z-index: 1;
            content: '';
            top: 100%;
            left: 50%;
            height: 0;
            width: 0;
        }
    }
    .leaderboard {
        cursor: pointer;
    }
    .text {
        padding: 3px 5px;
        top: 30px;
        left: 30px;
    }
}
.list-container {
    height: 250px;
    overflow-y: scroll;
}
.container-height {
    overflow-y: scroll;
    height: 300px;
}
.checkuser {
    border-top: 1px solid #efd59c;
    margin-top: 5px;
}
</style>
